import { atom } from 'jotai'

import { Action, Config } from './schemas'

export const accessTokenAtom = atom('')
export const domainAtom = atom('')
export const submitUrlAtom = atom('')
export const oktaEnvAtom = atom('')
export const actionAtom = atom<Action | false>(false)
export const configAtom = atom<Config | false>(false)
export const errorAtom = atom('')
