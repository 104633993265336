import { useAtom } from 'jotai'
import { useCallback, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

import { Button } from '@nike/eds'

import { DevControls } from './components/DevControls'
import { FormFooter } from './components/FormFooter'
import { FormHeader } from './components/FormHeader'
import { Action, ActionType, Config } from './cx-form'
import styles from './Form.module.styl'
import { FormField } from './FormField'
import { actionAtom } from './util/atoms'
import { fillTemplate, formDataToPayload, getDefaultValues, showField } from './util/form-util'
import { useSubmitForm } from './util/hooks'

type FormGeneratorProps = {
  actionType: ActionType
  config: Config
  onError?: ({ error }: { error: string }) => void
  onSuccess?: ({ data }: { data: unknown }) => void
  showDevControls: boolean | undefined
}

export function FormGenerator({
  actionType,
  config,
  onError,
  onSuccess,
  showDevControls,
}: FormGeneratorProps) {
  const [action, setAction] = useAtom(actionAtom)

  // Update action atom whenever config changes (either initially or fetched)
  useEffect(() => {
    if (!config) return
    const action = config.spec.actions.find((a: Action) => a.type === actionType)
    if (action) {
      setAction(action)
    }
  }, [actionType, config, setAction])

  const {
    handleSubmit,
    watch,
    formState: { dirtyFields, isSubmitting, isValid },
    reset,
  } = useFormContext()

  const { mutate } = useSubmitForm()

  useEffect(() => {
    if (action) {
      // Use reset to set default values after initial form render
      // https://stackoverflow.com/a/64307087
      reset(getDefaultValues(action))
    }
  }, [action, reset])

  const onSubmit = useCallback(
    (data: Record<string, unknown>) => {
      if (!action || !mutate) return

      let payload: unknown = formDataToPayload(action, data)

      if (action.payloadTemplate) {
        payload = fillTemplate(action.payloadTemplate, payload as Record<string, unknown>)
      }

      // post payload as request params
      // @ts-expect-error payload can be a string or object
      mutate(payload, {
        onSuccess: (_, variables) => {
          onSuccess && onSuccess({ data: variables })
        },
        onError: (error: string) => {
          onError && onError({ error })
        },
      })
    },
    [action, mutate, onError, onSuccess]
  )

  if (!action) {
    // still loading
    return <></>
  }
  return (
    <>
      <div className={styles.Form}>
        <>
          <FormHeader title={action.header?.title} text={action.header?.text} />
          {action.fields.map((field, index) => {
            return showField(field.showIf, watch) ? (
              <div className='eds-spacing--my-24' key={`FormFieldWrap-${field.name}-${index}`}>
                <FormField
                  field={field}
                  key={`FormField-${field.name}`}
                  isDirty={true === dirtyFields[field.name]}
                />
              </div>
            ) : null
          })}
          <FormFooter title={action.footer?.title} text={action.footer?.text} />
        </>

        {config && config.spec && action ? (
          <Button
            className='eds-spacing--mt-12'
            type='submit'
            onClick={handleSubmit(onSubmit)}
            disabled={isSubmitting || !isValid}
          >
            {action.type === ActionType.Create ? 'Create' : 'Submit'}
          </Button>
        ) : null}
      </div>

      {showDevControls && <DevControls />}
    </>
  )
}
