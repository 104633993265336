import { useQuery } from '@tanstack/react-query'
import { useSetAtom } from 'jotai'
import 'rc-slider/assets/index.css'
import { useEffect } from 'react'

import { Icon } from '@nike/eds'

import styles from './Form.module.styl'
import { FormGenerator } from './FormGenerator'
import { accessTokenAtom, configAtom, domainAtom, errorAtom, oktaEnvAtom } from './util/atoms'
import { cpaasConfigFetcher } from './util/hooks'
import { ActionType, Config } from './util/schemas'

export type FormProps = {
  // Required if config is a URL
  accessToken?: string

  // Domain name of the CPaaS proxy server
  domain: string

  // Name of form config. A cpaas-proxy instance may support different "flavors"
  // of the same form such as Aviatrix vs AWS VPCs. Each is fetched by a unique name.
  name: string

  // One of 'create', 'update', 'delete'
  action: ActionType

  // Config for testing. If provided, the config will not be fetched from the proxy.
  // This supports hard-coding of a form for testing or situations where live data is
  // not available. Props `domain`, `name`, and `action` are still required.
  testConfig?: Config

  // Okta env ('qa' or 'prod') for form to use, e.g. SelectWaffleIron widget
  // Default: prod
  env?: string

  // Function (or component) that receives an object of fetching/parsing errors
  onError: ({ error }: { error: unknown }) => JSX.Element

  // Function called when form is submitted successfully
  onSuccess: ({ data }: { data: unknown }) => JSX.Element

  showDevControls?: boolean
}

export function Form({
  accessToken = '',
  domain,
  name,
  action: actionType,
  testConfig,
  env,
  onError,
  onSuccess,
  showDevControls,
}: FormProps): JSX.Element {
  const setOktaEnv = useSetAtom(oktaEnvAtom)
  const setDomain = useSetAtom(domainAtom)
  const setError = useSetAtom(errorAtom)
  const setConfig = useSetAtom(configAtom)
  const setToken = useSetAtom(accessTokenAtom)

  useEffect(() => {
    setDomain(domain)
    setOktaEnv(env || '')
    setToken(accessToken)
  }, [accessToken, domain, env, setDomain, setOktaEnv, setToken])

  // Only fetch if we have an access token and no test config
  const fetchEnabled = Boolean(!!accessToken && !testConfig)
  const {
    data: fetchedConfig,
    error: fetchError,
    isFetching,
  } = useQuery({
    enabled: fetchEnabled,
    queryKey: ['config', domain, name, actionType],
    queryFn: cpaasConfigFetcher(domain, name, accessToken, setError),
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    if (fetchedConfig) {
      setConfig(fetchedConfig)
    } else if (testConfig) {
      setConfig(testConfig)
    }
  }, [fetchedConfig, setConfig, testConfig])

  if (fetchError) return onError({ error: fetchError })
  if (fetchEnabled && !accessToken) {
    return onError({ error: Error('Form could not be fetched: no access token provded.') })
  }

  return (
    <>
      {isFetching ? (
        <div className={styles.centered}>
          <Icon name='NikeSwoosh' size='l' className={styles.swooshing} />
        </div>
      ) : (
        <FormGenerator
          config={testConfig || fetchedConfig}
          actionType={actionType}
          showDevControls={showDevControls}
          onError={onError}
          onSuccess={onSuccess}
        />
      )}
    </>
  )
}
