import cx from 'classnames'

import styles from './Code.module.styl'

export function Code({ children, className, isText = false }: Props) {
  return (
    <div className={cx(styles.Code, className)}>
      <pre className='eds-color-background-disabled' style={{ overflow: 'auto' }}>
        {isText ? children : JSON.stringify(children, (_: unknown, v: unknown) => v, 4)}
      </pre>
    </div>
  )
}

type Props = {
  children: unknown
  className?: string
  isText?: boolean // Text or JSON
}
