import { QueryClientProvider } from '@tanstack/react-query'
import { Provider as JotaiProvider } from 'jotai'
import { FormProvider, useForm } from 'react-hook-form'

import { Form, FormProps } from './Form'
import { queryClient } from './util/queryClient'

/**
 * Sets up react-hook-form, react-query, and Jotai providers
 */
export function FormWrapper(props: FormProps) {
  const form = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {},
    delayError: 700, // give user a chance to correct accidental errors/typos
  })

  return (
    <QueryClientProvider client={queryClient}>
      <JotaiProvider>
        <FormProvider {...form}>
          <Form {...props} />
        </FormProvider>
      </JotaiProvider>
    </QueryClientProvider>
  )
}
