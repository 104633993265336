import cx from 'classnames'
import { useAtomValue } from 'jotai'
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { Button } from '@nike/eds'

import { actionAtom, configAtom } from '../util/atoms'
import { fillTemplate, formDataToPayload } from '../util/form-util'
import { Code } from './Code'
import styles from './DevControls.module.styl'

export function DevControls() {
  const { watch } = useFormContext()
  const [showPanel, setShowPanel] = useState(false)
  const formStateUnprepared = watch()
  const config = useAtomValue(configAtom)
  const action = useAtomValue(actionAtom)
  const payload = action ? formDataToPayload(action, formStateUnprepared) : {}
  const filledTemplate = action ? fillTemplate(action.payloadTemplate || '', payload) : ''
  return (
    <div className='eds-spacing--mt-48'>
      <Button
        onClick={() => setShowPanel(!showPanel)}
        size='small'
        variant={showPanel ? 'primary' : 'secondary'}
      >
        Debug
      </Button>
      {showPanel && (
        <div className={cx('eds-spacing--p-16 eds-spacing--m-24', styles.Debug)}>
          <div className='eds-spacing--4'>
            {action && action.payloadTemplate && (
              <div className='eds-spacing--mb-24'>
                <h4>Payload via `payloadTemplate`:</h4>
                <Code isText={typeof filledTemplate === 'string'}>{filledTemplate}</Code>
              </div>
            )}
            <div className='eds-spacing--mb-24'>
              <h4>Form State:</h4>
              <Code isText={typeof payload === 'string'}>{payload}</Code>
            </div>
            <div>
              <h4>Config:</h4>
              <Code>{config}</Code>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
