import { RegisterOptions } from 'react-hook-form'

import { Action, FieldDefType, Option, ShowIfRule } from './schemas'

export function formDataToPayload(action: Action, formData: Record<string, unknown>) {
  const payload: Record<string, unknown> = {}
  action.fields.map((field: FieldDefType) => {
    const valueOrOption = formData[field.name]
    // Convert select options { value; 'a', label: 'A' } to just their string values 'a'
    // @ts-expect-error it's okay, calm down
    const value = typeof valueOrOption === 'object' ? valueOrOption.value : valueOrOption

    // Only include fields that are shown in the form
    if (showField(field.showIf, (name: string) => formData[name])) {
      payload[field.name] = value
    }
  })

  return payload
}

export function fillTemplate(template: string, payload: Record<string, unknown>): string {
  if (!template) return ''
  let filled = template

  Object.keys(payload).forEach((name) => {
    const value = getValueOrOptionValue(payload[name])
    filled = filled.replaceAll(`{{ ${name} }}`, value || '')
  })
  return filled
}

function getValueOrOptionValue(valueOrOption: unknown) {
  // @ts-expect-error it's okay, calm down
  return typeof valueOrOption === 'object' ? valueOrOption.value : valueOrOption
}

export function showField(rules: ShowIfRule[] | undefined, getValue: (name: string) => unknown) {
  if (!rules?.length) return true

  return rules.every((showIfRule) => {
    const otherFieldValue = getValue(showIfRule.field)
    return otherFieldValue === showIfRule.hasValue
  })
}

export function toOptionArray(options: string[] | Option[]): SelectOption[] {
  if (!options.length) return []
  return options.map((option: string | Option) => {
    if (typeof option === 'string') {
      return { value: option, label: option }
    } else {
      return option
    }
  })
}
type SelectOption = { value: string; label: string }

export function getDefaultValues(action: Action) {
  const defaults: Record<string, unknown> = {}
  action.fields.forEach((field) => {
    const { default: defaultValue, options, name } = field
    if (defaultValue !== undefined) {
      const value = options
        ? toOptionArray(options).find((o) => o.value === defaultValue)
        : defaultValue

      // Support 2 levels of names via dot-notation
      const [first, second] = name.split('.')
      if (second) {
        if (typeof defaults[first] === 'undefined') {
          defaults[first] = { [second]: value }
        } else if (typeof defaults[first] === 'object') {
          defaults[first][second] = value
        }
      } else {
        defaults[first] = value
      }
    }
  })
  return defaults
}

export function getLabel(field: FieldDefType) {
  return field.label || field.name || ''

  // Oops, EDS adds '*' during validation errors, so don't add one here.
  // But keeping this here so devs know why * are not initially shown.
  // if (field.validators?.find((rule) => !!rule.required)) {
  //   label = `${label} #`
  // }
}

// Fields may specify any of the validation rules supported by react-hook-form:
// https://react-hook-form.com/docs/useform/register
export function getValidationRules(field: FieldDefType): RegisterOptions {
  if (!field.validators) return {}

  const rules: RegisterOptions = {}
  field.validators.forEach((rule) => {
    const { required, min, max, minLength, maxLength, message, pattern } = rule
    switch (true) {
      case required !== undefined && required !== false:
        rules.required = message || 'This field is required.'
        break
      case min !== undefined:
        rules.min = { value: min, message: message || `Must be at least ${min}.` }
        break
      case max !== undefined:
        rules.max = { value: max, message: message || `Must be at most ${max}.` }
        break
      case minLength !== undefined:
        rules.minLength = {
          value: minLength,
          message: message || `Must have a length of at least ${minLength}.`,
        }
        break
      case maxLength !== undefined:
        rules.maxLength = {
          value: maxLength,
          message: message || `Must have a length of at most ${maxLength}.`,
        }
        break
      case pattern !== undefined:
        rules.pattern = {
          value: new RegExp(pattern),
          message: message || `Must match regex pattern: ${pattern}`,
        }
        break
      default:
    }
  })

  return rules
}
