import { useMutation } from '@tanstack/react-query'
import { useAtomValue } from 'jotai'
import yaml from 'yaml'

import { accessTokenAtom, actionAtom, configAtom, domainAtom } from './atoms'
import { ActionType } from './schemas'

export const cpaasProxyUrls = {
  configs: (domain: string) => `https://${domain}/cpaas-proxy/v1/configs`,
  config: (domain: string, name: string) => `https://${domain}/cpaas-proxy/v1/configs/${name}`,

  // Note: ending slash here is required due to a routing bug in the proxy.
  create: (domain: string, kind: string, group: string) =>
    `https://${domain}/cpaas-proxy/v1/gvk/${kind}.${group}/`,
}

export function cpaasConfigFetcher(
  domain: string,
  name: string,
  accessToken: string,
  setError: (error: string) => void
) {
  return () => {
    return fetch(cpaasProxyUrls.config(domain, name), {
      method: 'get',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }).then(async (response) => {
      return await parseResponseAndSaveAtoms(response, setError)
    })
  }
}

async function parseResponseAndSaveAtoms(response: Response, setError: (error: string) => void) {
  const contentType = response.headers.get('content-type')
  let json
  try {
    if (contentType?.includes('json')) {
      json = await response.json()
    } else if (contentType?.includes('yaml')) {
      const text = await response.text()
      json = yaml.parse(text)
    } else {
      setError(`Unsupported content type: ${contentType}`)
      json = {}
    }
  } catch (error) {
    setError(`Failed to parse form config: ${error}`)
    return {}
  }
  return json.config || json
}

export function useSubmitForm() {
  const accessToken = useAtomValue(accessTokenAtom)
  const config = useAtomValue(configAtom)
  const action = useAtomValue(actionAtom)
  const domain = useAtomValue(domainAtom)
  let url = ''
  let method = ''
  if (config && config.spec && action) {
    const { kind, group } = config.spec.groupVersionKind
    switch (action.type) {
      case ActionType.Create:
        url = cpaasProxyUrls.create(domain, kind, group)
        method = 'post'
        break
      default:
        break
    }
  }

  return useMutation({
    // @ts-expect-error it's okay, calm down
    mutationFn: (data: object) => {
      if (url) {
        return fetch(url, {
          method,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(data),
        })
      } else {
        console.log('No url to submit to')
        console.log('data:', data)
        return Promise.resolve()
      }
    },
  })
}
