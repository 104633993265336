import { useAtomValue } from 'jotai'
import { RegisterOptions } from 'react-hook-form'

import { Text, TextGroup, Tooltip } from '@nike/eds'

import { SelectWaffleIron } from './components/SelectWaffleIron'
import { Slider } from './components/Slider'
import { Checkbox, RadioGroup, Select, TextField, Toggle } from './eds-wrapped-form-components'
import { accessTokenAtom, oktaEnvAtom } from './util/atoms'
import { getLabel, getValidationRules, toOptionArray } from './util/form-util'
import { FieldDataType, FieldDefType, FieldWidgetType } from './util/schemas'

type FormFieldProps = {
  field: FieldDefType
  isDirty: boolean
}

export function FormField({ field, isDirty }: FormFieldProps): JSX.Element {
  const { name, help, options, subtitle, type, widget } = field
  const label = getLabel(field)
  const registerOptions: RegisterOptions = getValidationRules(field) as RegisterOptions
  const commonProps = { label, id: name, name, message: help, subtitle }
  const key = `field-${name}`
  const env = useAtomValue(oktaEnvAtom)
  const token = useAtomValue(accessTokenAtom)

  switch (true) {
    case widget === 'SelectWaffleIron': {
      return env === 'prod' ? (
        <SelectWaffleIron {...commonProps} env={env} token={token} key={key} />
      ) : (
        <div>
          <TextField
            {...commonProps}
            registerOptions={registerOptions}
            key={key}
            autoComplete='off'
          />
          <Tooltip
            isDark
            bodySlot={
              <TextGroup>
                <Text>
                  The Waffle Iron dropdown widget will be shown in production environments.
                </Text>
                <Text>In this context your Okta token is for QA, which is not yet supported.</Text>
              </TextGroup>
            }
            placement='right'
          >
            <Text font='body-3' className='eds-color--secondary'>
              Why is this not a dropdown?
            </Text>
          </Tooltip>
        </div>
      )
    }

    case !!options && options.length > 0 && widget === FieldWidgetType.Radio: {
      return (
        <RadioGroup
          {...commonProps}
          options={toOptionArray(options || [])}
          valueSelected=''
          key={key}
        />
      )
    }

    case !!options && options.length > 0: {
      return (
        // @ts-expect-error - props are fine
        <Select
          {...commonProps}
          isMulti={widget === FieldWidgetType.MultipleChoice}
          options={toOptionArray(options || [])}
          key={key}
        />
      )
    }

    case type === FieldDataType.Boolean && widget === FieldWidgetType.Toggle: {
      const props = { id: name, label, name, checked: false }
      if (!isDirty) {
        // Initialize value (workaround for useForm's `defaultValues` not working for bools)
        props.checked = Boolean(field.default) === true
      }
      return <Toggle {...props} key={key} />
    }

    case type === FieldDataType.Boolean && widget === FieldWidgetType.Checkbox: {
      const props = { id: name, label, name, checked: false }
      if (!isDirty) {
        // Initialize value (workaround for useForm's `defaultValues` not working for bools)
        props.checked = Boolean(field.default) === true
      }
      return <Checkbox {...props} key={key} />
    }

    // Automatically use a slider if field has type number and has both min and max validation rules
    case type === FieldDataType.Number && widget === FieldWidgetType.Slider:
    case type === FieldDataType.Number &&
      widget !== FieldWidgetType.Text &&
      !!field.validators?.find((rule) => rule.min !== undefined) &&
      !!field.validators?.find((rule) => rule.max !== undefined): {
      const min = field.validators?.find((rule) => rule.min !== undefined)?.min
      const max = field.validators?.find((rule) => rule.max !== undefined)?.max

      return (
        <Slider
          key={key}
          label={label}
          message={help}
          name={name}
          min={min}
          max={max}
          {...field.widgetProps}
          testId={`slider-${name}`}
        />
      )
    }

    case type === FieldDataType.Number && widget !== FieldWidgetType.Slider:
      // Number field that does not have both min and max validation rules
      // is shown as a text field with type=number.
      return (
        <TextField
          {...commonProps}
          type='number'
          registerOptions={registerOptions}
          key={key}
          autoComplete='off'
        />
      )

    case typeof type === 'undefined':
    case type === FieldDataType.String && !options: {
      return (
        <TextField
          {...commonProps}
          registerOptions={registerOptions}
          key={key}
          autoComplete='off'
        />
      )
    }

    default:
      return <></>
  }
}
